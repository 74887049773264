
// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"
import Sortable from "sortablejs"
import Rails from "@rails/ujs"


export default class extends Controller {

  connect() {
    this.sortable = Sortable.create(this.element, {
      group: 'shared',
      animation: 150,
      onEnd: this.end.bind(this)
    })
  }


  indentless(event) {
    let id = event.currentTarget.dataset.id
    let data = new FormData()

    let a = document.getElementById("doc"+id)
    let wid = parseInt(a.style.width) - 30
    if (wid >= 0) {
      a.style.width = wid.toString() + "px" 
    }

    Rails.ajax({
      url: this.data.get("url").replace(":id", id).replace(":act", "less"),
      type: 'PATCH',
      data: data
    })
  }


  indentmore(event) {
    let id = event.currentTarget.dataset.id
    let data = new FormData()

    let a = document.getElementById("doc"+id)
    let wid = parseInt(a.style.width) + 30
    if (wid < 150) {
      a.style.width = wid.toString() + "px" 
    }

    Rails.ajax({
      url: this.data.get("url").replace(":id", id).replace(":act", "more"),
      type: 'PATCH',
      data: data
    })
  }

  end(event) {
    let id = event.item.dataset.id
    let data = new FormData()
    data.append("position", event.newIndex + 1)

    Rails.ajax({
      url: this.data.get("url").replace(":id", id).replace(":act", "updown"),
      type: 'PATCH',
      data: data
    })
  }
}

